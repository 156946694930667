import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Section } from "~/components/elements/Section";
import {
  BodyCopy,
  BodyCopySizeOption,
} from "~/components/elements/typography/BodyCopy";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";

export default function CategoryHero({ name, uri, category, description }) {
  let bgColour;

  switch (category.heroColour) {
    case "blue":
      bgColour = "bg-blue";
      break;
    case "green":
      bgColour = "bg-green-1";
      break;
    case "purple":
      bgColour = "bg-purple-1";
      break;
    case "orange":
      bgColour = "bg-orange-1";
      break;
  }

  return (
    <Section
      backgroundColour="transparent"
      classname={`md:!py-0 text-white max-w-none md:h-[400px] ${bgColour}`}
    >
      <div className="container grid items-center h-full md:px-0 md:grid-cols-2">
        <div className="space-y-3 md:ml-6 md:py-12 ">
          <div className="pl-10 space-y-4 border-l border-l-white">
            <Heading text={name} type="h1" size={HeadingSizeOption.h1Large} />
            <div className="md:max-w-[480px] opacity-70 ">
              <BodyCopy text={description} size={BodyCopySizeOption.Large} />
            </div>
          </div>
        </div>
        <div className="hidden h-full md:block">
          <Image
            image={category.heroIllustration}
            objectFit="cover"
            className="h-full"
          />
        </div>
      </div>
    </Section>
  );
}
