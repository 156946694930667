import { Link } from "gatsby";
import React from "react";
import useCategories from "~/hooks/useCategories";

const Pagination = ({ pageContext, category }) => {
  const { nodes } = useCategories();

  const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber } =
    pageContext;

  const activeCategory = nodes.findIndex((node) => node?.name === category);

  return (
    <div>
      {pageContext.numberOfPages > 1 && (
        <div className="flex items-center justify-center mt-12 xl:mt-16 pb-[70px] md:pb-[139px]">
          {previousPagePath && (
            <span>
              <Link to={previousPagePath}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.01792 6.4303C0.921625 6.32979 0.859208 6.1805 0.850201 6.00708L0.94185 6.00232L0.85 5.99932L0.85 5.9993L0.957328 5.99652L0.850321 5.9895L0.850814 5.98192C0.854211 5.92945 0.858608 5.86155 0.880208 5.79186C0.904183 5.7145 0.946822 5.64088 1.01848 5.56773L5.30303 1.04354C5.30308 1.04349 5.30313 1.04344 5.30318 1.04339L5.30324 1.04332L5.41215 1.14646C5.58327 0.965038 5.90414 0.944569 6.0855 1.12785L1.01792 6.4303ZM1.01792 6.4303C1.01804 6.43043 1.01816 6.43056 1.01828 6.43068L5.30324 10.9553L5.30321 10.9553L5.30582 10.958C5.56134 11.2148 5.94902 11.2059 6.18847 10.9798C6.43044 10.7514 6.44081 10.3355 6.21322 10.0943L6.21309 10.0941L2.93315 6.62566M1.01792 6.4303L2.93315 6.62566M2.93315 6.62566L10.5238 6.62566C10.8699 6.62566 11.15 6.3454 11.15 5.99931C11.15 5.65321 10.8699 5.37296 10.5238 5.37296L2.93315 5.37296L2.93315 6.62566Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                  />
                </svg>
              </Link>
            </span>
          )}

          <ul className="flex mx-2 md:mx-7">
            {[...Array(numberOfPages)].map((un, i) => {
              const num = i + 1;
              return (
                <li key={`pagi-${i}`} className="mx-2">
                  <Link
                    to={`${nodes[activeCategory]?.uri}${num > 1 ? num : ""}`}
                    className="px-3 pb-2  border-b  border-white border-opacity-[0.11] hover:border-opacity-100 "
                    activeClassName=" px-3 pb-2 border-b border-white border-opacity-100   "
                  >
                    {num}
                  </Link>
                </li>
              );
            })}
          </ul>

          {nextPagePath && (
            <span>
              <Link to={nextPagePath}>
                <svg
                  className="rotate-180"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.01792 6.4303C0.921625 6.32979 0.859208 6.1805 0.850201 6.00708L0.94185 6.00232L0.85 5.99932L0.85 5.9993L0.957328 5.99652L0.850321 5.9895L0.850814 5.98192C0.854211 5.92945 0.858608 5.86155 0.880208 5.79186C0.904183 5.7145 0.946822 5.64088 1.01848 5.56773L5.30303 1.04354C5.30308 1.04349 5.30313 1.04344 5.30318 1.04339L5.30324 1.04332L5.41215 1.14646C5.58327 0.965038 5.90414 0.944569 6.0855 1.12785L1.01792 6.4303ZM1.01792 6.4303C1.01804 6.43043 1.01816 6.43056 1.01828 6.43068L5.30324 10.9553L5.30321 10.9553L5.30582 10.958C5.56134 11.2148 5.94902 11.2059 6.18847 10.9798C6.43044 10.7514 6.44081 10.3355 6.21322 10.0943L6.21309 10.0941L2.93315 6.62566M1.01792 6.4303L2.93315 6.62566M2.93315 6.62566L10.5238 6.62566C10.8699 6.62566 11.15 6.3454 11.15 5.99931C11.15 5.65321 10.8699 5.37296 10.5238 5.37296L2.93315 5.37296L2.93315 6.62566Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                  />
                </svg>
              </Link>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Pagination;
